import React, { forwardRef, useEffect, useState, useRef } from 'react'

import { Link } from 'react-router-dom'
import { useFetchJobs } from '../../useFetch'

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import MaterialTable, { MTableToolbar, MTableBody } from 'material-table'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'

import './JobListingContent.css'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const JobListingContent = ({ locationType }) => {
  const tableRef = React.createRef()

  const useStyles = makeStyles((theme) => ({
    paper: {
      width: '100%',
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
    table: {
      minWidth: 600,
    },
  }))

  useEffect(() => {
    setZipCodeError(false)
    setZipCodeHelperText('')
  }, [locationType])

  useEffect(() => {
    window.scrollTo(0, 0)
    const tables = document.getElementsByTagName('table')
    if (tables.length > 0) {
      if (tables[0].className) {
        if (tables[0].className.indexOf('rgMasterTable rgClipCells') < 0) {
          tables[0].className += ' rgMasterTable rgClipCells'
        }
      } else {
        tables[0].className = ' rgMasterTable rgClipCells'
      }
      tables[0].parentNode.id = 'gvJobs_GridHeader'
      tables[0].parentNode.className = 'rgHeaderDiv'
    }
    const tbodies = document.getElementsByTagName('tbody')
    if (tbodies.length > 0) {
      const trs = tbodies[0].getElementsByTagName('tr')
      if (trs != null) {
        for (var i = 0; i < trs.length; i += 1) {
          if (i === 0) {
            continue
          }
          const tr = trs[i]
          tr.id = '__' + (i - 1)
          if (tr.className) {
            if (tr.className.indexOf('rgRow searchResultPost') < 0) {
              tr.className += ' rgRow searchResultPost'
            }
          } else {
            tr.className = ' rgRow searchResultPost'
          }
          tr.setAttribute('itemscope', '')
          tr.setAttribute('itemtype', 'https://schema.org/JobPosting')
          const tds = tr.getElementsByTagName('td')
          if (tds.length === 4) {
            tds[0].setAttribute('itemprop', 'title')
            tds[2].setAttribute('itemprop', 'occupationalCategory')
            tds[3].setAttribute('itemprop', 'employmentType')
          }
        }
      }
    }
  }, [])

  const isValidZip = (zip) => /^\d{5}$/.test(zip)

  const [maxDistance, setMaxDistance] = useState(0)

  const [zipCode, setZipCode] = useState('')
  const [zipCodeError, setZipCodeError] = useState(false)
  const [zipCodeHelperText, setZipCodeHelperText] = useState('')

  const [titleFilter, setTitleFilter] = useState('')
  const [locationFilter, setLocationFilter] = useState('')
  const [categoryFilter, setCategoryFilter] = useState('')
  const [employmentTypeFilter, setEmploymentTypeFilter] = useState('')

  const [titleSort, setTitleSort] = useState('')
  const [locationSort, setLocationSort] = useState('')
  const [categorySort, setCategorySort] = useState('')
  const [employmentTypeSort, setEmploymentTypeSort] = useState('')

  const [searchText, setSearchText] = useState('')

  const [clearBtnDisabled, setClearBtnDisabled] = useState(true)
  const [toggle, setToggle] = useState(true)

  const baseUrl = `/careers-api/v1/jobs/${locationType}`
  const url =
    zipCode && isValidZip(zipCode) && maxDistance
      ? baseUrl + `/${zipCode}/${maxDistance}`
      : baseUrl

  const { loading, jobs, isError } = useFetchJobs(url)

  const classes = useStyles()

  var filterStates = {
    0: titleFilter || null,
    1: locationFilter || null,
    2: categoryFilter || null,
    3: employmentTypeFilter || null,
  }

  const handleFilterChange = (id, value) => {
    if (id === 0) {
      filterStates['0'] = value
    } else if (id === 1) {
      filterStates['1'] = value
    } else if (id === 2) {
      filterStates['2'] = value
    } else if (id === 3) {
      filterStates['3'] = value
    }
  }

  let zipTextInput = useRef(null)

  const handleClearAll = (event) => {
    setZipCodeError(false)
    setZipCodeHelperText('')
    if (!tableRef || !tableRef.current || !tableRef.current.state) {
      return
    }

    const zipCodeClearBtn = document.getElementById('zipCodeClearBtn')
    if (zipCodeClearBtn) {
      click(zipCodeClearBtn)
    }
    const buttons = document.getElementsByTagName('button')
    for (var i = 0; i < buttons.length; i += 1) {
      if (buttons[i].getAttribute('aria-label') === 'Clear Search') {
        setSearchText('')
        click(buttons[i])
        break
      }
    }

    filterStates = {}
    setTitleFilter(filterStates['0'])
    setLocationFilter(filterStates['1'])
    setCategoryFilter(filterStates['2'])
    setEmploymentTypeFilter(filterStates['3'])
    setTitleSort(null)
    setLocationSort(null)
    setCategorySort(null)
    setEmploymentTypeSort(null)
    setZipCode('')

    setToggle(!toggle)

    setTimeout(function () {
      const zip = document.getElementById('zipCode')
      zip.focus()
    }, 100)
  }

  const handleClearZip = (event) => {
    event.preventDefault()
    document.getElementById('zipCode').value = ''
    if (zipCodeError) {
      setZipCodeError(false)
      setZipCodeHelperText('')
      setZipCode('')
    }
    setClearBtnDisabled(true)
  }

  const handleZipCodeChange = (event) => {
    setZipCodeError(false)
    setZipCodeHelperText('')
    if (event.target.value) {
      setClearBtnDisabled(false)
    } else {
      setClearBtnDisabled(true)
    }
  }

  const setTableStates = () => {
    if (!tableRef || !tableRef.current || !tableRef.current.state) {
      return
    }
    const state = tableRef.current.state
    setSearchText(state.searchText)
    setTitleFilter(filterStates['0'])
    setLocationFilter(filterStates['1'])
    setCategoryFilter(filterStates['2'])
    setEmploymentTypeFilter(filterStates['3'])

    if (state.orderBy === 0) {
      setTitleSort(state.orderDirection)
      setLocationSort(null)
      setCategorySort(null)
      setEmploymentTypeSort(null)
    } else if (state.orderBy === 1) {
      setTitleSort(null)
      setLocationSort(state.orderDirection)
      setCategorySort(null)
      setEmploymentTypeSort(null)
    } else if (state.orderBy === 2) {
      setTitleSort(null)
      setLocationSort(null)
      setCategorySort(state.orderDirection)
      setEmploymentTypeSort(null)
    } else if (state.orderBy === 3) {
      setTitleSort(null)
      setLocationSort(null)
      setCategorySort(null)
      setEmploymentTypeSort(state.orderDirection)
    }
  }

  const click = (node) => {
    var evt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    node.dispatchEvent(evt)
  }

  const handleZipSearch = (event, source) => {
    event.preventDefault()
    var zip = document.getElementById('zipCode').value
    if (zip != null) {
      zip = zip.trim()
    }
    if (zip && !isValidZip(zip)) {
      setZipCodeError(true)
      setZipCodeHelperText('invalid zip code')
      setTimeout(() => {
        zipTextInput.current.focus()
      }, 100)
      return
    }

    setZipCodeError(false)
    setZipCodeHelperText('')
    setTableStates()
    setZipCode(zip)

    if (source === 'select') {
      setMaxDistance(event.currentTarget.dataset.value)
    } else {
      setMaxDistance(document.getElementById('maxDistance').innerText)
    }
    setTimeout(() => {
      zipTextInput.current.focus()
    }, 100)
  }

  const focusZipInputField = (input) => {
    if (input) {
      setTimeout(() => {
        document.getElementById('zipCode').focus()
        window.scrollTo(0, 0)
      }, 100)
    }
  }

  return (
    <>
      <section id='main'>
        <div id='mainContainer' className='container'>
          <div id='mainRow' className='row'>
            <div id='rightCol' className='col-lg-12'>
              <div
                className='contsainer alert '
                style={{
                  background: '#f9f9f9',
                  border: 'solid 1px #eee',
                  padding: '30px 20px',
                }}
              >
                <div className=' '>
                  <h6>
                    <strong>Search by Location & Distance</strong>
                  </h6>
                </div>

                <div className='row '>
                  <div className='col-lg-5' style={{}}>
                    <TextField
                      id='zipCode'
                      placeholder='search by zip code'
                      key='zipCodeTextField'
                      className='col-lg-12'
                      defaultValue={
                        zipTextInput.current
                          ? zipTextInput.current.value
                          : zipCode
                      }
                      inputRef={zipTextInput}
                      ref={focusZipInputField}
                      autoFocus={true}
                      error={!!zipCodeError}
                      helperText={zipCodeHelperText}
                      aria-label='zip code'
                      onKeyPress={(event) => {
                        if (
                          event.code === 'Enter' ||
                          event.code === 'NumpadEnter' ||
                          event.which === 13
                        ) {
                          handleZipSearch(event, 'zipTextField')
                        }
                      }}
                      onChange={(event) => {
                        handleZipCodeChange(event)
                      }}
                      onFocus={(event) => {
                        setTableStates()
                      }}
                      InputProps={{
                        autoComplete: 'new-password',
                        startAdornment: (
                          <InputAdornment position='start'>
                            <SearchIcon className='MuiSvgIcon-root MuiSvgIcon-fontSizeSmall' />
                          </InputAdornment>
                        ),

                        endAdornment: (
                          <IconButton
                            id='zipCodeClearBtn'
                            className='MuiSvgIcon-root'
                            disabled={
                              zipTextInput.current
                                ? !zipTextInput.current.value
                                : true
                            }
                            onClick={handleClearZip}
                            aria-label='Clear zip code search'
                          >
                            <ClearIcon
                              className='MuiSvgIcon-fontSizeSmall'
                              aria-label='clear'
                            />
                          </IconButton>
                        ),
                      }}
                    />
                  </div>

                  <div className='col-lg-3' style={{}}>
                    <TextField
                      id='maxDistance'
                      select
                      defaultValue={maxDistance ? maxDistance : 25}
                      helperText='max distance (miles)'
                    >
                      {[10, 25, 50, 100].map((value) => (
                        <MenuItem
                          key={value}
                          value={value}
                          onClick={(event) => handleZipSearch(event, 'select')}
                        >
                          {value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className='col-lg-2 '>
                    <Button
                      class='btn btn-outline-dark btn-sm col-lg-12 text-uppercase font-weight-bold'
                      onClick={(event) => handleZipSearch(event, 'searchBtn')}
                    >
                      Search
                    </Button>
                  </div>
                  <div className='col-lg-2'>
                    <Button
                      class='btn btn-outline-dark btn-sm col-lg-12 text-uppercase font-weight-bold'
                      onClick={(event) => handleClearAll(event)}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </div>

              {loading && (
                <div style={{ textAlign: 'center' }}>
                  <img alt='Loading' src='/img/loader.gif' />
                </div>
              )}
              {!loading && isError && <div>Error...</div>}
              {!loading && jobs && jobs.data && (
                <Paper id='rightContent' className={classes.paper}>
                  <MaterialTable
                    className='rgMasterTable rgClipCells'
                    tableRef={tableRef}
                    title=''
                    icons={tableIcons}
                    columns={[
                      {
                        field: 'title',
                        title: (
                          <Tooltip title={'Sort by Title'}>
                            <span>Title</span>
                          </Tooltip>
                        ),
                        filterPlaceholder: 'search in titles',
                        defaultFilter: titleFilter,
                        defaultSort: titleSort || null,
                        cellStyle: { width: '30%' },
                        render: (row) => {
                          return (
                            <Link
                              to={[
                                '/job/',
                                row['title'].replace(/\?|<|>|&|\/|\s/g, '-'),
                                '-',
                                row['id'],
                              ].join('')}
                              className='clt-row'
                            >
                              {row.title}
                            </Link>
                          )
                        },
                      },
                      {
                        field: 'location',
                        render: (row) => {
                          return (
                            <>
                              {(row['distance'] || row['distance'] === 0) && (
                                <div>
                                  {row['location']}
                                  <br />
                                  <p className='text-primary'>
                                    {row['distance']
                                      .toFixed(2)
                                      .replace(/\.?0*$/, '')}
                                    {row['distance'].toFixed(2) === '1.00'
                                      ? ' mile'
                                      : ' miles'}
                                  </p>
                                </div>
                              )}
                              {!row['distance'] && row['distance'] !== 0 && (
                                <div>{row['location']}</div>
                              )}
                            </>
                          )
                        },
                        title: (
                          <Tooltip title={'Sort by Location'}>
                            <span>Location</span>
                          </Tooltip>
                        ),
                        filterPlaceholder: 'search in locations',
                        defaultFilter: locationFilter,
                        defaultSort: locationSort || null,
                        cellStyle: { width: '30%' },
                      },
                      {
                        field: 'category',
                        title: (
                          <Tooltip title={'Sort by Category'}>
                            <span>Category</span>
                          </Tooltip>
                        ),
                        filterPlaceholder: 'search in categories',
                        defaultFilter: categoryFilter,
                        defaultSort: categorySort || null,
                        cellStyle: { width: '30%' },
                      },
                      {
                        field: 'employmentType',
                        title: (
                          <Tooltip title={'Sort by Employment Type'}>
                            <span>FT/PT</span>
                          </Tooltip>
                        ),
                        filterPlaceholder: 'search in employment types',
                        defaultFilter: employmentTypeFilter,
                        defaultSort: employmentTypeSort || null,
                        cellStyle: { width: '10%' },
                      },
                      {
                        field: 'description',
                        title: 'Description',
                        hidden: true,
                        searchable: true,
                      },
                      {
                        field: 'qualifications',
                        title: 'Qualifications',
                        hidden: true,
                        searchable: true,
                      },
                      {
                        field: 'essentialFunctions',
                        title: 'Essential Functions',
                        hidden: true,
                        searchable: true,
                      },
                    ]}
                    data={jobs.data}
                    c
                    components={{
                      Toolbar: (props) => {
                        return (
                          <>
                            <div className='row'>
                              <div className='col-lg-3'>
                                <MTableToolbar {...props} />
                              </div>
                            </div>
                          </>
                        )
                      },
                      Body: (props) => {
                        return (
                          <MTableBody
                            {...props}
                            onFilterChanged={(columnId, value) => {
                              props.onFilterChanged(columnId, value)
                              handleFilterChange(columnId, value)
                            }}
                          />
                        )
                      },
                    }}
                    localization={{
                      toolbar: {
                        searchPlaceholder: 'global search',
                      },
                      body: {
                        emptyDataSourceMessage: <div>No result found</div>,
                      },
                    }}
                    options={{
                      searchText: searchText ? searchText : '',
                      emptyRowsWhenPaging: false,
                      pageSize: 25,
                      filtering: true,
                      pageSizeOptions: [10, 25, 100],
                      headerStyle: {
                        position: 'sticky',
                        top: 0,
                        fontWeight: 'bold',
                      },
                      rowStyle: (data, index) => {
                        if (index % 2 === 0) {
                          return { backgroundColor: 'rgba(0,0,0,.05)' }
                        }
                      },
                      search: true,
                    }}
                    actions={[]}
                  />
                </Paper>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default JobListingContent
