import './App.css'
import { useFetchMessages } from './useFetch'
import InsideBnPage from './pages/inside/InsideBnPage'
import LocationsPage from './pages/locations/LocationsPage'
import JobListingPage from './pages/job-listing/JobListingPage'
import JobDetailPage from './pages/detail/JobDetailPage'
import HomePage from './pages/home/HomePage'
import Header from './pages/common/Header'
import Footer from './pages/common/Footer'
import Error from './pages/error/Error'
import { useEffect, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

function App() {
  const { messages } = useFetchMessages()
  let location = useLocation()

  return (
    <>
      <Header />
      <Switch>
        <Route path='/inside-ps'>
          <InsideBnPage />
        </Route>
        <Route path='/locations'>
          <LocationsPage />
        </Route>
        <Route path='/retail-jobs'>
          <JobListingPage
            title='Retail Job'
            locationType='retail'
            message={messages && ['retail_page'] ? messages['retail_page'] : ''}
          />
        </Route>
        <Route path='/corporate-jobs'>
          <JobListingPage
            title='Corporate Job'
            locationType='corporate'
            message={
              messages && ['corporate_page'] ? messages['corporate_page'] : ''
            }
          />
        </Route>
        <Route path='/distribution-jobs'>
          <JobListingPage
            title='Distribution Job'
            locationType='dist_center'
            message={
              messages && messages['distribution_page']
                ? messages['distribution_page']
                : ''
            }
          />
        </Route>

        <Route path='/job/:titleAndId' component={JobDetailPage} />

        <Route path='/' exact>
          <HomePage />
        </Route>

        <Route path='*' component={Error} />
      </Switch>
      <Footer />
    </>
  )
}

export default App
