import React, { useEffect } from 'react'
import InsideBnSectionOne from './InsideBnSectionOne'
import InsideBnSectionTwo from './InsideBnSectionTwo'
import InsideBnSectionThree from './InsideBnSectionThree'

const InsideBnPage = () => {
  useEffect(() => {
    document.title = 'Inside Paper Source'
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className='container-fluid'>
        <div className='row mediaContainer'>
          <div className='col-12'>
            <img
              src='/img/ps/Inside-PS-Hero-1599x458.jpg'
              className='d-block w-100 img-fluid'
              alt='Explore Why Paper Source Is a Great Place to Work'
            />
          </div>
        </div>
      </div>

      <br />
      <div className='container '>
        <div className='row'>
          <div className='col-12'>
            <h1>
              <strong>Explore Why Paper Source Is a Great Place to Work</strong>
            </h1>
          </div>
        </div>
      </div>

      <br />

      <InsideBnSectionOne />
      <InsideBnSectionTwo />
      <InsideBnSectionThree />
    </>
  )
}

export default InsideBnPage
