import React from 'react'

const InsideBnSectionThree = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-lg-12'>
          <h4>
            <strong>Benefits &amp; Perks</strong>{' '}
          </h4>
          <p className='lead'>
            The Paper Source employment package includes a whole host of health and financial benefits for eligible positions. It’s another way of saying, we want our employees to stay with us for a long time.
          </p>
        </div>
      </div>
      <br />

      <div className='row'>
        <div className='col-lg-5 '>
          <ul className='benefits-list'>
            <li>
              <em className='icon-h-sign'></em>Medical plan (3 options)
            </li>
            <li>
              <em className='icon-medkit'></em>Prescription coverage
            </li>
            <li>
              <em className='icon-user'></em>Dental plan
            </li>
            <li>
              <em className='icon-eye-open'></em>Vision discount plan
            </li>
            <li>
              <em className='icon-stethoscope'></em>Healthcare flexible spending
              account (FSA)
            </li>
            <li>
              <em className='icon-stethoscope'></em>Healthcare spending
              account (HSA)
            </li>
            <li>
              <em className='icon-usd'></em>401(k) savings plan with company
              match
            </li>
            <li>
              <em className='icon-plus-sign-alt'></em>Paid maternity and
              parental leave
            </li>
            <li>
              <em className='icon-plus-sign-alt'></em>Disability coverage
            </li>
          </ul>
        </div>
        <div className='col-md-5 col-md-offset-1'>
          <ul className='benefits-list'>
            <li>
              <em className='icon-heart-empty'></em>Life and accident insurance
            </li>
            <li>
              <em className='icon-suitcase'></em>Business travel insurance
            </li>
            <li>
              <em className='icon-shopping-cart'></em>Merchandise discounts
              (books, devices and accessories)
            </li>
            <li>
              <em className='icon-calendar'></em>Vacation and other paid time
              off
            </li>
            <li>
              <em className='icon-truck'></em>Tax-free transit program
            </li>
            <li>
              <em className='icon-book'></em>Tuition reimbursement
            </li>
            <li>
              <em className='icon-book'></em>Access to exclusive Employee
              Assistance Program and WorkLife Services
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default InsideBnSectionThree
