import React from 'react'

const InsideBnSectionOne = () => {
  return (
    <>
      <div className='container-fluid'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <section id='content'>
                <article>
                  <div>
                    <h3 className=' text-success'>
                      At Paper Source, our vision is to curate an assortment that sparks delight and fosters thoughtful connections. We understand that what we do is more than just work, it’s a vital force for connection and creativity.
                    </h3>
                    <hr />
                    <ul className='list-unstyled'>
                      <li>
                        <h6 className='font-weight-bolder'>CREATIVITY</h6>
                        Creativity lives in the heart of every employee – resourcefulness is turning ideas into reality.
                      </li>
                      <br />
                      <li>
                        <h6 className='font-weight-bolder'>ENTREPRENEURIAL SPIRIT</h6>
                        The best ideas come when people can take pride and ownership of their results.
                      </li>
                      <br />

                      <li>
                        <h6 className='font-weight-bolder'>
                          COLLABORATIVE LEADERSHIP
                        </h6>
                        Communicating up, down and across the organization is vital to facilitate interpersonal connections and partnerships.
                      </li>

                      <br />

                      <li>
                        <h6 className='font-weight-bolder'>
                          CUSTOMER-CENTRICITY
                        </h6>
                        Our number one goal is providing first-class service to both our internal and external customers.
                      </li>
                      <br />

                      <li>
                        <h6 className='font-weight-bolder'>
                          PASSION FOR THE BRAND
                        </h6>
                        Innovation and original design are always at the forefront, ensuring everyone has the quality products they need to creatively express themselves.
                      </li>
                    </ul>
                  </div>
                  <div> </div>
                </article>
              </section>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
    </>
  )
}

export default InsideBnSectionOne
