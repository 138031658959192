import { useState, useEffect, useCallback } from 'react'

export const useFetchJobs = (url) => {
  const [loading, setLoading] = useState(true)
  const [jobs, setJobs] = useState({})
  const [isError, setIsError] = useState(false)

  const getJobs = useCallback(async () => {
    setLoading(true)
    fetch(url, {
      method: 'get',
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
      .then((resp) => {
        if (resp.status >= 200 && resp.status <= 299) {
          return resp.json()
        } else {
          throw new Error(resp.statusText)
        }
      })
      .then((page) => {
        setJobs({
          data: page.content,
          page: page.number,
          totalCount: page.totalElements,
        })
        setLoading(false)
        setIsError(false)
      })
      .catch((error) => {
        setLoading(false)
        setIsError(true)
        console.log(error)
      })
  }, [url])

  useEffect(() => {
    getJobs()
  }, [url, getJobs])
  return { loading, jobs, isError }
}

export const useFetchJobDetail = (url) => {
  const [loading, setLoading] = useState(true)
  const [jobDetail, setJobDetail] = useState([])
  const [isError, setIsError] = useState(false)

  const getJobDetail = useCallback(async () => {
    fetch(url)
      .then((resp) => {
        if (resp.status >= 200 && resp.status <= 299) {
          return resp.json()
        } else {
          throw new Error(resp.statusText)
        }
      })
      .then((jobDetail) => {
        setJobDetail(jobDetail)
        setLoading(false)
        setIsError(false)
      })
      .catch((error) => {
        setLoading(false)
        setIsError(true)
        console.log(error)
      })
  }, [url])

  useEffect(() => {
    getJobDetail()
  }, [url, getJobDetail])
  return { loading, jobDetail, isError }
}

export const useFetchTestimonials = () => {
  const [loading, setLoading] = useState(true)
  const [testimonials, setTestimonials] = useState([])
  const [isError, setIsError] = useState(false)

  const getTestimonials = useCallback(async () => {
    fetch('/careers-api/v1/internal/testimonials')
      .then((resp) => {
        if (resp.status >= 200 && resp.status <= 299) {
          return resp.json()
        } else {
          throw new Error(resp.statusText)
        }
      })
      .then((testimonials) => {
        setTestimonials(testimonials)
        setLoading(false)
        setIsError(false)
      })
      .catch((error) => {
        setLoading(false)
        setIsError(true)
        console.log(error)
      })
  }, [])

  useEffect(() => {
    getTestimonials()
  }, [getTestimonials])
  return { loading, testimonials, isError }
}

export const useFetchMessages = () => {
  const [messages, setMessages] = useState([])

  const getMessages = useCallback(async () => {
    fetch('/careers-api/v1/messages')
      .then((resp) => {
        if (resp.status >= 200 && resp.status <= 299) {
          return resp.json()
        } else {
          throw new Error(resp.statusText)
        }
      })
      .then((msgs) => {
        setMessages(msgs)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    getMessages()
  }, [getMessages])
  return { messages }
}
