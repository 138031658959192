import React, { useEffect } from 'react'

import JobListingHeader from './JobListingHeader'
import JobListingContent from './JobListingContent'

const JobListingPage = ({ title, message, locationType }) => {
  useEffect(() => {
    document.title = title
  }, [title, message, locationType])

  return (
    <>
      <JobListingHeader
        locationType={locationType}
        message={message}
        headerText={title + ' Search'}
      />
      <JobListingContent locationType={locationType} />
    </>
  )
}

export default JobListingPage
