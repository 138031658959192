import React, { useEffect } from 'react'

const Error = () => {
  useEffect(() => {
    document.title = 'Error'
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className='navClearingExtended'></div>

      <div className='container'>
        <div className='row'>
          <div className='col-3'>
            <h1 className='display-2 font-weight-bold'>
              <strong>404</strong>
            </h1>
          </div>

          <div className='col-9'>
            <h1>
              <strong>
                Sorry, but the page you were looking for could not be found.
              </strong>
            </h1>
            <p class='lead'>
              Although there are many reasons why this page could not be found,
              here are a few reasons listed below.{' '}
            </p>

            <ul>
              <li>Mistyped URL</li>
              <li>Copy-and-paste error</li>
              <li>Broken link</li>
              <li>Truncated link</li>
              <li>Moved content</li>
              <li>Deleted content</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Error
