import React from 'react'

const JobListingHeader = ({ message, headerText }) => {
  return (
    <>
      <div className='navClearingExtended'></div>
      <div className='container'>
        {message && (
          <>
            <br />
            <div
              className='container'
              dangerouslySetInnerHTML={{ __html: message }}
            ></div>
          </>
        )}

        <div className='row'>
          <div className='col-lg-12'>
            <h1>
              <strong>{headerText}</strong>
            </h1>
          </div>
        </div>
      </div>
      <br />
    </>
  )
}

export default JobListingHeader
