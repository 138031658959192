import React from 'react'

const HomePageSectionOne = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <h1>
            <strong>
              Create a career with a company that combines innovation, passion and potential.
            </strong>
          </h1>
        </div>
      </div>
    </div>
  )
}

export default HomePageSectionOne
