import React from 'react'

const HomePageCarousel = () => {
  return (
    <div className='container-fluid navClearing'>
      <div className='mediaContainer'>
        <img
          className='d-block w-100 img-fluid'
          src='img/ps/Careers-Hero-3200x1300.jpg'
          alt='Hero image'
        />
      </div>
    </div>
  )
}

export default HomePageCarousel
