import React, { useEffect } from 'react'
import HomePageCarousel from './HomePageCarousel'
import HomePageSectionOne from './HomePageSectionOne'
import HomePageSectionTwo from './HomePageSectionTwo'
import './HomePage.css'

const HomePage = ({ authed }) => {
  useEffect(() => {
    document.title = 'Careers'
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <HomePageCarousel />
      <br />
      <HomePageSectionOne />
      <br />
      <HomePageSectionTwo authed={authed} />
    </>
  )
}

export default HomePage
