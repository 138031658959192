import React from 'react'

export const Footer = () => {
  return (
    <footer>
      <div className='container-fluid footer-container-fluid footerFont text-muted'>
        <br />
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='small'>
                <p>
                  As an Affirmative Action Employer, Barnes & Noble hires
                  qualified people to work for its Paper Source subsidiary to
                  perform the many tasks necessary for the success of our
                  business and is committed to diversity in the workplace. An
                  essential part of this policy is providing equal employment
                  opportunity for all. All employment practices and
                  decisions—including those involving application procedures,
                  recruitment or recruitment advertising, hiring, placement, job
                  assignment, transfer, promotion, demotion, training, rates of
                  pay or other forms of compensation, benefits, discipline,
                  leave of absence, layoff, recall, termination and general
                  treatment during employment—will be conducted without regard
                  to age, race, color, ancestry, national origin, citizenship
                  status, military or veteran status, religion, creed,
                  disability, sex, sexual orientation, marital status, medical
                  condition as defined by applicable law, genetic information,
                  gender, gender identity, gender expression (including
                  transgender status), hairstyle, height and/or weight,
                  pregnancy, childbirth and related medical conditions,
                  reproductive health decisions, or any other self-identified,
                  perceived or actual characteristic protected by applicable
                  federal, state, or local laws and ordinances, and will comply
                  with all applicable laws.
                </p>

                <p>
                  Please tell us if you require a reasonable accommodation to
                  apply for a job or to perform your job. Examples of reasonable
                  accommodation include making a change to the application
                  process or work procedures, providing documents in an
                  alternate format, using a sign language interpreter, or using
                  specialized equipment. Contact{' '}
                  <a href='tel:8007995335'>(800) 799-5335</a>.{' '}
                  <a href='https://www.barnesandnoble.com/h/help/about/terms-of-use'>
                    Terms of Use
                  </a>
                  , Copyright, and{' '}
                  <a href='https://www.barnesandnoble.com/h/help/privacy-policy'>
                    Privacy Policy
                  </a>{' '}
                  © 1997-{new Date().getFullYear()} Barnes & Noble Booksellers,
                  Inc. 33 East 17th Street, New York, NY 10003
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid'>
          <div className='container'>
            <div className='row '>
              <div className='col-lg-12 text-center'>
                <small>
                  <a href='/rss' className='text-dark'>
                    RSS Feed
                  </a>
                  &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                  <a href='https://www.papersource.com/' className='text-dark'>
                    PaperSource.com
                  </a>
                </small>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
