import React from 'react'

const InsideBnSectionTwo = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-lg-12'></div>
      </div>
    </div>
  )
}

export default InsideBnSectionTwo
